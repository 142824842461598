<template>
  <div>
    <div class="container" v-loading="loading">
      <el-breadcrumb class="mt20" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/productCenter' }">{{$t('page.console.menu.product_center')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{detail[isEn + `title`]}}</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="product-detail">
        <div class="images-view">
          <el-image class="images-view-top" :src="currentImg"></el-image>
          <swiper :options="swiperOption" v-if="detail.images.length > 0" class="banner">
            <swiper-slide :class="{active: currentImg == banner}" :key="`banner`+ i" v-for="(banner, i) in detail.images">
              <img :src="banner">
            </swiper-slide>
            <!-- Add Pagination -->
            <!--<div class="swiper-pagination" slot="pagination"></div>-->
            <!-- Add Arrows -->
            <div class="banner-swiper-button swiper-button-prev" v-if="detail.images.length > 5" slot="button-prev"></div>
            <div class="banner-swiper-button swiper-button-next" v-if="detail.images.length > 5" slot="button-next"></div>
          </swiper>
        </div>


        <div class="info bg-grey">
          <div class="info-top">
            <h2 class="title">{{detail[isEn + `title`]}}</h2>
            <span class="model">{{detail[isEn + `model`]}}</span>
            <p class="desc">{{detail[isEn + `desc`]}}</p>
          </div>
        </div>

        <div class="info-content" v-html="detail[isEn + `content`]"></div>

        <share :title="detail.title" :image="detail.images ? detail.images[0] : ''"></share>
      </div>
    </div>
  </div>
</template>

<script>
import {getListTaxonomy, getProduct} from '@/api/os/admin';
import config from '@/config';
import share from '@/components/Share';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
var self;

export default {
  components: {
    swiper,
    swiperSlide,
    share
  },
  data() {
    return {
      flag: true,
      options: {
        productType: [],
        productApp: [],
        subsidiary: [],
        workpiece: []
      },
      detail: {
        images: [],
        title: ''
      },
      swiperOption: {
        loop: true,
        observer: true,
        slidesPerView : 5,
        // slidesPerView: "auto",
        // loopedSlides: 0,
        // centeredSlides: true,
        spaceBetween: 10,
        slideToClickedSlide: true,
        autoplay: {
          disableOnInteraction: false // 手动切换之后继续自动轮播
        },
        /*pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },*/
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          tap(ev) {
            //获取不到this，提前定义self = this
            if (self.flag) {

              setTimeout(() => {
                //处理方法
                if(ev.target.currentSrc) {
                  self.currentImg = ev.target.currentSrc
                }

                self.flag = true;
              }, 300);
            }
            self.flag = false;
          },
          slideChangeTransitionEnd: function () {
            // console.log(this.activeIndex)
            let data = this.imagesToLoad[this.activeIndex];
            self.currentImg = this.imagesToLoad[this.activeIndex].currentSrc
            // 切换结束时，告诉我现在是第几个slide
          }
        }
      },
      currentImg: ''
    }
  },
  created() {
    self = this
    this.getTaxonomy()
    this.get()
  },
  computed: {
    // add
    isEn() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    }
  },
  methods: {
    getTaxonomy() {
      getListTaxonomy().then(res => {
        this.options = res.data.data
      })
    },
    get() {
      this.loading = true
      getProduct({
        id: this.$route.params.id,
      }).then(res => {
        this.loading = false
        let data = res.data.data
        if(data.images && data.images.length > 0) {
          this.currentImg = data.images[0]

          this.swiperOption.loopedSlides = data.images.length
          if(data.images.length <= 5) {
            this.swiperOption.loop = false
          }
        }
        this.detail = data
      })
    }
  },
  // watch: {
  //   currentImg (index) {
  //     return this.detail.images[index+1];
  //   }
  // }
}
</script>

<style lang="less" scoped>
.product-detail{
  margin: 20px auto;
  .images-view{
    width: 500px;
    min-height: 500px;
    float: left;
    .images-view-top{
      width: 100%;
      //height: 350px;
      //border-radius: 5px;
    }
    .banner{
      width: 496px;
      height: 91.2px;
      border-radius: 2px;
      margin: 10px 0;
      //border: 1px dashed #c0ccda;
      //padding: 2px;
      .swiper-slide{
        cursor: pointer;
      }
      .swiper-slide.active{
        border: 2px solid #00923F;
      }
    }
  }
  .info{
    width: calc(100% - 520px);
    margin-left: 20px;
    float: left;
    .info-top{
      padding: 20px;
      .title{
        font-size: 2rem;
      }
      .model{
        color: #00923F;
        font-size: 2rem;
        margin-top: 10px;
        display: block;
      }
      .desc{
        font-size: 1rem;
        margin-top: 10px;
      }
    }
  }
  .info-content{
    width: calc(100% - 560px);
    margin-left: 520px;
    overflow: auto;
    padding: 20px;
  }
}
@media screen and (max-width: 768px){
  .product-detail{
    .images-view{
      width: 100%;
      .banner{
        height: 60px;
      }
    }
    .info{
      width: 100%;
      margin: 0;
    }
    .info-content{
      width: 96%;
      margin: 0 2%;
      padding: 20px 0;
    }
  }

  .container .el-breadcrumb{
    margin-left: 10px;
  }
}
</style>